<template>
    <div>
        <CForm ref="form" class="needs-validation">
            <CRow>

                <CCol style="margin-bottom: .5rem;">
                    <CCol>
                        <CRow>
                            <label style="margin-right: .5rem;"> Resim </label>
                            <Input
                                type="file"
                                horizontal
                                :required="actionType == 'create'"
                                @change="handleFileUpload($event, 'fileImage')"
                                accept="image/*"
                            />
                        </CRow>
                    </CCol>

                    <CCol>
                        <span style="font-size: 10px;"> Yalnızca resim formatı kabul edilmektedir. </span>
                        <CButton @click="openPreview('fileImage', data)" color="link" v-if="data.fileImage || data.contentURL"> Görüntüle </CButton>
                    </CCol>
                </CCol>

                <CCol  style=" margin-bottom: .5rem;">
                    <CCol>
                        <CRow>
                            <label style="margin-right: .5rem;">WEBP Resim </label>
                            <Input
                                type="file"
                                horizontal
                                @change="handleFileUpload($event, 'fileWebp')"
                                accept="image/webp"
                            />
                        </CRow>
                    </CCol>

                    <CCol>
                        <span style="font-size: 10px;"> Yalnızca webp formatı kabul edilmektedir. </span>
                        <CButton @click="openPreview('fileWebp', data)"  color="link" v-if="data.fileWebp || data.webpContentURL"> Görüntüle </CButton>
                    </CCol>
                </CCol>

                <CCol style=" margin-bottom: .5rem;">
                    <CCol>
                        <CRow>
                            <span style="margin-right: .5rem;"> Video </span>
                            <Input
                                type="file"
                                horizontal
                                @change="handleFileUpload($event, 'fileVideo')"
                                accept="video/mp4"
                            />
                        </CRow>
                    </CCol>
                    
                    <CCol>
                        <span style="font-size: 10px;"> Yalnızca mp4 formatı kabul edilmektedir. </span>
                        <CButton @click="openPreview('fileVideo', data)" color="link" v-if="data.fileVideo || data.videoContentURL"> Görüntüle </CButton>
                    </CCol>
                </CCol>

            </CRow>

            <CRow>
                <CCol></CCol>
                <CCol v-if="data.image && data.image.id" >
                    <CButton color="danger" class="w-100"  @click="cancelEdit"> Düzenlemeyi İptal Et </CButton>
                </CCol>
                <CCol> 
                    <CButton color="success" class="w-100" @click="modalHandle('save')"> {{ data.id ? 'Düzenlemeyi Kaydet' : 'Kaydet'}} </CButton>

                </CCol>
                <CCol></CCol>

            </CRow>

            <ConfirmModal
                :show="openModal" 
                :title="modalTitle" 
                :openModalEvent="openModalEvent"
                :confirm="confirm"
                :desc="modalDesc"
                :noFooter="noFooter"
                :size="size"
            />
        </CForm>

        <CDataTable
            :items="contentImages"
            :fields="fields"
            :loading="loading"
            hover
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="editRecord(lastItem)"
            v-on:refresh="getContents"
            class="modalTable"
        >
            <template #show_details="{item}">
                <td class="py-2">
                    <CDropdown
                        toggler-text="İşlemler"
                        class="m-2"
                        color="info"
                        size="sm"
                    >
                        <CDropdownItem @click="openPreview(item.videoFlag ? 'fileVideo' : item.webpFlag ? 'fileWebp' : 'fileImage', item)">
                            Önizle
                        </CDropdownItem>

                        <CDropdownItem @click="openModalEvent('İçerik Resim Silme (#'+item.id+')', 'İçerik resmini silmek üzeresiniz. Bunu yapmak istediğinizden emin misiniz?', 'destroy')">
                            Sil
                        </CDropdownItem>
                    </CDropdown>
                </td>
            </template>

            <template #videoContentURL="{item}">
              <td>
                <a 
                    v-if="item.videoContentURL" 
                    @click="openPreview('fileVideo', item)"
                    href="javascript:void(0)"
                >
                    {{ item.videoContentURL }} 
                </a>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>

            <template #webpContentURL="{item}">
              <td>
                <a 
                    v-if="item.webpContentURL" 
                    @click="openPreview('fileWebp', item)"
                    href="javascript:void(0)"
                > 
                    {{ item.webpContentURL }}
                </a>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>

            <template #contentURL="{item}">
              <td>
                <a 
                    v-if="item.contentURL" 
                    @click="openPreview('fileImage', item)"
                    href="javascript:void(0)"
                >
                     {{ item.contentURL }}
                </a>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
        </CDataTable>
    </div>
     
</template>

<script>
    import ConfirmModal from '../../components/confirmModal.vue'
    import moment from 'moment'
    export default{
        name: "contentImageForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            modalHandle: Function
        },
        components:{
             ConfirmModal
        },
        computed:{
            contentImages(){
                return this.$store.getters.contentImages;
            },
            loading(){
                return this.$store.getters.contentLoading;
            }

        },
        created: function(){
            this.data = {...this.params}
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },
            validated:  function(){
                if(!this.data.fileImage && this.actionType == 'create'){
                    const toast = {
                        msg: 'Content alanı zorunludur lütfen bir resim yükleyiniz',
                        header: 'Hata',
                        color: 'danger'
                    }
                    this.$store.commit('showToast', toast)
                }
            }
        },
        data: function(){
            return{
                data: {},
                modalDesc: '',
                modalTitle: '',
                openModal: false,
                noFooter: false,
                size: 'lg',
                fields: [
                    {key: 'show_details', label: 'İşlemler',_style: 'font-size:12px'},
                    {key: 'viewCount', label: 'Görüntüleme Sayısı', _style: 'font-size:12px'},
                    {key: 'contentURL', label: 'Resim URL', _style: 'font-size:12px'},
                    {key: 'videoContentURL', label: 'Video URL', _style: 'font-size:12px'},
                    {key: 'webpContentURL', label: 'WEBP URL', _style: 'font-size:12px'},
                ],
            }
        },
        methods:{
            rowClicked: function(item){
                this.lastItem = item;
            },

            editRecord: function(data){
                this.data.image = {...data}

                for(var i = 0; i <this.contentImages.length; i++){

                    this.$set(this.contentImages[i], '_selected', false);
                    this.$set(this.contentImages[i], '_classes', '');

                }

                const val = Boolean(this.contentImages.find(e => e.id == data.id)._selected);
                this.$set(this.contentImages.find(e => e.id == data.id), '_classes', !val ? 'selected' : '');
            },

            cancelEdit: function(){
                this.data.image = {}
            },

            dateFormat(data){
                return moment(data).format('DD.MM.YYYY')
            },

            getContents: async function(){

                let params = {
                    contentId: this.data.content.id
                }

                await this.$store.dispatch('content_images', params)

            },

            openPreview: function(data, item){

                let content='<div style="display: flex; align-items:center; justify-content: center;"><span style="position: absolute; color: #fff;';
                let url = ''
                let style = '' 

                if(this.data.content && this.data.content.mobileFlag){
                    content += 'right: 20px; left: 20px; text-align: center; bottom: 140px;">'+
                        '<small>'+this.data.content.text+'</small><h4>'+this.data.content.title+'</h4> </span>'
                    style = 'width: 500px; height: 720px;'
                }else {
                    content += 'right: 100px; bottom: 100px; text-align: right; z-index: 3;">'+
                        '<small>'+this.data.content.text+'</small><h4>'+this.data.content.title+'</h4> </span>'
                    style = 'width: 768px; height: 432px;'
                }

                if(item){

                    let fileName = (data == 'fileWebp' ? item.webpContentURL : data == 'fileImage' ? item.contentURL : item.videoContentURL)
                    url =  process.env.VUE_APP_SERVERINFO_WEBDOMAIN+'/static/images/content/banner/' + fileName;

                }else{

                    if(this.data[data]){
                        url = URL.createObjectURL(this.data[data])
                    } else {
                        let fileName = (data == 'fileWebp' ? this.data.webpContentURL : data == 'fileImage' ? this.data.contentURL : this.data.videoContentURL)
                        url =  process.env.VUE_APP_SERVERINFO_WEBDOMAIN+'/static/images/content/banner/' + fileName
                    }

                }
                
                if(data == 'fileVideo'){
                    content += '<video src="'+url+'" style="'+style+'" autoplay loop />'
                } else {
                    content += '<img src="'+url+'" style="'+style+'"/>'
                }

                content += '</div>'
                this.openModalEvent('Önizleme', content)
            },

            openModalEvent(title, desc, type){
                this.noFooter = true;
                this.size = 'lg'

                if(type == 'destroy'){
                    this.noFooter = false;
                    this.size = 'sm'
                }

                this.openModal = !this.openModal
                this.modalTitle = title
                this.modalDesc = desc
            },

            confirm: async function() {
                let data = {
                    id : this.lastItem.id
                }

                await this.$store.dispatch('content_image_delete', data)

                if(this.$store.getters.contentStatus.success) {
                    this.openModalEvent()
                    this.getContents()
                    this.cancelEdit()
                }

            },

            handleFileUpload: function(event, target){
                let file = event.target.files[0]
                let status = true
                let msg = {}

                if(target == 'fileVideo'){
                    if(file.size > 10000000){
                        status = false
                        msg = {
                            msg: 'Video boyutu en fazla 10 mb olabilir',
                            header: 'Hata',
                            color: 'danger'
                        }
                    }
                }else if(target == 'fileWebp' || target == 'fileImage'){
                    if(file.size > 1500000){
                        status = false
                        msg = {
                            msg: 'Görsel boyutu en fazla 1.5 mb olabilir',
                            header: 'Hata',
                            color: 'danger'
                        }
                    }
                }
                if(status){
                    if(!this.data.image)
                        this.data.image = {}

                    this.data.image[target] = file;
                }else{
                    event.target.value = ''
                    this.$store.commit('showToast', msg)
                }
            }
        }
    }
</script>