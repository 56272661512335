<template>
    <CModal
        :show="show"
        :centered="true"
        :title="title"
        :size="size ? size : 'sm'"
        color="dark"
        @update:show="updateModalShow"
    >
        <div class="flexCenter" v-show="loading">
            <CSpinner  color="primary"/>
        </div>
        <div v-show="!loading">
            <iframe v-if="isFrame" ref="desc" style="border:0; width:100%; height: 60vh;"/>
            <div v-else ref="desc" v-html="desc" />   
        </div>
        <template #footer>
            <span v-if="!noFooter">
                <CButton :disabled="loading" style="margin-right: 1rem;"  @click="() => confirm(data, actionType)" color="success"> {{actionType == 'print' ? 'Yazdır' : 'Evet'}} </CButton>
                <CButton :disabled="loading" @click="updateModalShow" color="dark"> {{actionType == 'print' ? 'Kapat' : 'Hayır' }}</CButton>
            </span>
            <span v-else>
            </span>
        </template>
    </CModal>
</template>

<script>
    export default{
        name: 'Modal',
        props:{
            show: Boolean,
            title: String,
            desc: String,
            openModalEvent: Function,
            confirm: Function,
            actionType: String,
            data: [Object, Boolean, String],
            noFooter: Boolean,
            size: String,
            isFrame: Boolean,
            loading: Boolean,
        },
        methods:{
            updateModalShow: function(){
                this.openModalEvent();
            },
        }
    }

</script>